var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.isLoading}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-layout',{staticClass:"align-center my-2 py-2 pl-10 pr-6",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("#")])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center text-center"},[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("Amount")])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("Currency")])])]),_c('v-flex',{attrs:{"md3":""}},[_c('div',{staticClass:"d-flex align-center ml-2"},[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("Ref No.")])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("Status")])])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("Payee")])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("Date")])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0 primary--text font-weight-bold"},[_vm._v("Actions")])])])],1):_vm._e(),(_vm.filteredPayables && _vm.filteredPayables.length > 0)?_c('v-row',_vm._l((_vm.displayedItems),function(payable,i){return _c('v-col',{key:i,staticClass:"py-0 px-0 px-md-3 ma-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('PaymentTable',{attrs:{"index":i + 1,"id":payable.id,"paymentRef":payable.reference,"currency":payable.currency === 'NGN'
              ? '₦'
              : payable.currency === 'USD'
              ? '$'
              : payable.currency === null
              ? '₦'
              : payable.currency,"approvedBy":payable.approved_by.email,"payee":payable.counterparty.vendorname,"date":_vm._f("date")(payable.created_at),"amount":payable.total,"status":payable.status},on:{"openDetails":function($event){return _vm.openDetails(payable.id)}}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('PayableTableCard',{attrs:{"index":i + 1,"id":payable.id,"paymentRef":payable.reference,"currency":payable.currency === 'NGN'
              ? '₦'
              : payable.currency === 'USD'
              ? '$'
              : payable.currency === null
              ? '₦'
              : payable.currency,"approvedBy":payable.approved_by.email,"payee":payable.counterparty.vendorname,"date":_vm._f("date")(payable.created_at),"amount":payable.total,"status":payable.status},on:{"openDetails":function($event){return _vm.openDetails(payable.id)}}}):_vm._e()],1)}),1):_c('div',{staticClass:"d-flex justify-center align-center pt-6"},[_c('span',{staticClass:"text--primary text-h5 font-monospace"},[_vm._v("No Data available")])])],1),(_vm.filteredPayables && _vm.filteredPayables.length > 0)?_c('div',{staticClass:"pagination__container px-8"},[_c('BasePagination',{attrs:{"current-page":_vm.currentPage,"visible-pages":_vm.visiblePageValue,"total-pages":_vm.totalPages},on:{"page-changed":_vm.handlePageChange}})],1):_vm._e(),_c('div',[_c('v-navigation-drawer',{staticStyle:{"box-shadow":"0px 4px 16px rgba(192, 196, 202, 0.15)"},attrs:{"width":"360px","temporary":"","right":"","hide-overlay":true,"app":""},model:{value:(_vm.detailsDrawer),callback:function ($$v) {_vm.detailsDrawer=$$v},expression:"detailsDrawer"}},[(_vm.loadingDetails)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"100vh","width":"100%"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('PayableDetails',{attrs:{"entryId":_vm.singleEntryId},on:{"close":function($event){_vm.detailsDrawer = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }