<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <BaseTitleBar pageTitle="Payables" itemsText="Record(s)">
          <!--    :itemArray="getInvoices"
          :total-items="totalInvoice" -->
          <DropDownMenu
            btnText="Add New"
            icon="uploadIcon"
            height="54px"
            justify="right"
          >
            <template #titleButton>
              <!-- <v-list-item-group>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in inboxMenus"
                    :key="index"
                    @click="handleMenuAction(index)"
                  >
                    <v-list-item-content>
                      <v-list-item-title tag="button" slot="activator">
                        {{ item.title }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <template> {{ item.subtitle }}</template>
                        <template v-if="item.title == 'Receive Vendor Invoice'">
                          {{
                            organizationToken
                              ? organizationToken.data.hypn_email
                              : ""
                          }}
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-icon small class="lsit__icon"> {{ item.icon }}</v-icon>
                  </v-list-item>
                </v-list>
              </v-list-item-group>
              <UploadDialog ref="uploadDialog" /> -->
            </template>
          </DropDownMenu>
        </BaseTitleBar>

        <!-- summary cards for receivables -->
        <div
          v-if="loadingPayables"
          class="
            d-block d-md-flex
            justify-center
            align-center
            justify-md-space-around
            px-3 px-md-12
            pt-10
          "
        >
          <v-skeleton-loader
            class="mx-md-2 my-4"
            v-for="loader in 3"
            :key="loader"
            height="150px"
            width="100%"
            type="card"
          />
        </div>
        <div
          v-else
          class="
            container-wrapper
            d-sm-flex
            justify-center
            align-center
            py-6
            pb-md-0
            px-3
            pl-md-10
            pr-md-8
          "
        >
          <receivableCards
            class="ml-md-5 mr-md-3 mr-sm-3"
            :amount="payablesBalances ? getAmt(payablesBalances.total) : '--'"
            cardInfo="Total Payables"
          >
            <v-progress-linear
              rounded
              v-model="currentBillPercentage"
              color="#96EAD7"
              height="8"
              background-color="#FF6A6A"
            >
            </v-progress-linear>
            <div class="d-block">
              <p class="subTitle mb-0 pt-2 text-end">staff expense</p>
              <p class="subTitle__value text-end">
                {{ payablesBalances ? getAmt(payablesBalances.expense) : "--" }}
              </p>
            </div>
          </receivableCards>
          <receivableCards
            class="mx-md-3 mx-sm-1"
            style="min-height: 150px"
            :amount="
              payablesBalances
                ? Intl.NumberFormat('en-NG', {
                    maximumSignificantDigits: 3,
                  }).format(payablesBalances.vendors)
                : '--'
            "
            cardInfo="Total Vendors"
            :chart="true"
          >
            <PureVueChart
              :points="paymentPerMonthData"
              :width="150"
              :height="50"
              bar-color="#96ead7"
              :show-x-axis="true"
            />
          </receivableCards>
          <receivableCards
            class="ml-md-3 mr-md-6 ml-sm-3"
            :amount="payablesBalances ? payablesBalances.payees : '--'"
            cardInfo="Total Payees"
          >
            <v-progress-linear
              rounded
              v-model="currentPayeesPercentage"
              color="#19283D"
              height="8"
              background-color="#2BD5AE"
            >
            </v-progress-linear>
            <div class="d-block pb-4">
              <p class="subTitle mb-0 pt-2 pb-4 text-end">last 30 days</p>
              <!-- <p class="subTitle__value text-end">
                {{ payablesBalances ? payablesBalances.payees.last30days : 0 }}
              </p> -->
            </div>
          </receivableCards>
        </div>
        <!-- summary cards for receivables ends here -->
        <v-container class="mt-md-12">
          <v-row class="mx-md-10 pa-0">
            <v-col
              class="d-flex flex-column align-center justify-center"
              cols="12"
            >
              <v-card
                v-if="$vuetify.breakpoint.mdAndUp"
                flat
                width="100%"
                style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
              >
                <v-tabs v-model="tab">
                  <v-tab
                    class="mt-2"
                    v-for="item in items"
                    :key="item.tab"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 800;
                      font-size: 12px;
                      line-height: 15px;
                      text-transform: uppercase;
                    "
                    >{{ item.tab }}</v-tab
                  >

                  <v-spacer></v-spacer>

                  <v-btn
                    v-if="isClicked"
                    @click="toggleSearch"
                    plain
                    class="text-black mt-1 pt-4"
                    style="
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 20px;
                      letter-spacing: 0.55px;
                      text-transform: uppercase;
                      color: #7f919b;
                    "
                  >
                    search
                    <v-icon small right class="pr-1"> mdi-magnify </v-icon>
                  </v-btn>
                  <v-expand-x-transition v-else>
                    <v-text-field
                      v-model="search"
                      @blur="isClicked = true && !search"
                      @input="searchDataTable"
                      class="seacrh-field mt-2 mr-2"
                      dense
                      clearable
                      autofocus
                      hide-details="true"
                      persistent-placeholder
                      placeholder="Search"
                      append-icon="mdi-magnify"
                      filled
                    >
                    </v-text-field>
                  </v-expand-x-transition>
                </v-tabs>
              </v-card>

              <v-card width="100%" class="pb-12" flat>
                <component
                  v-bind:is="items[tab].content"
                  ref="payablesTable"
                  class="ml-0"
                ></component>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <!-- tabs for mobile devices -->
    <v-row
      class="px-0 mx-0"
      style="margin-top: 10vh"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-col class="ma-0 pa-0" cols="12">
        <v-bottom-navigation fixed class="pa-0 mx-0" dark>
          <v-tabs
            center-active
            class="ma-0"
            background-color="primary"
            v-model="tab"
          >
            <v-tab
              class="mt-2"
              v-for="item in items"
              :key="item.tab"
              style="
                font-family: Inter;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
              "
              >{{ item.tab }}</v-tab
            >
          </v-tabs>
        </v-bottom-navigation>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PureVueChart from "pure-vue-chart";
import CardAvatar from "@/components/CardAvatar.vue";
import SingleSummaryCard from "@/components/SingleSummaryCard.vue";
import ReceivableCards from "@/pages/receivables/components/receivableCards.vue";
import currencyFilter from "@/mixins/currencyFilter";
import Allpayables from "@/pages/payables/components/payablesTabs/AllPayables.vue";
import ScheduledPayables from "@/pages/payables/components/payablesTabs/ScheduledPayables.vue";
import PendingPayables from "@/pages/payables/components/payablesTabs/PendingPaybles.vue";
import PaidPayables from "@/pages/payables/components/payablesTabs/PaidPayables.vue";
import BudgetPayables from "@/pages/payables/components/payablesTabs/BudgetPayables.vue";
import PaymentDropDown from "@/includes/PaymentDropdown.vue";
import DropDownMenu from "@/includes/DropDownMenu.vue";
import BaseTitleBar from "@/components/BaseTitleBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "payables",
  data() {
    return {
      loadingPayables: false,
      currencyDefault: "NGN",
      paymentPerMonth: [1, 3, 2, 3, 4, 2],
      isClicked: true,
      tab: 0,
      search: "",
      items: [
        { tab: "All", content: "Allpayables" },
        // { tab: "Scheduled", content: "ScheduledPayables" },
        // { tab: "Pending", content: "PendingPayables" },
        { tab: "Paid", content: "PaidPayables" },
        //{ tab: "Budgets", content: "BudgetPayables" },
      ],
      inboxMenus: [
        {
          title: "Upload",
          subtitle: "pdf or jpg invoice",
          icon: "mdi-tray-arrowDown",
        },
        {
          title: "Form",
          subtitle: "create form",
          icon: "mdi-tray-arrow-down ",
        },
        {
          title: "Bank Account",
          subtitle: "connect your bank account",
          icon: "mdi-tray-arrowDown",
        },
        {
          title: "EMAIL TO:",
          subtitle: "brandname0923@process.finance",
          icon: "",
        },
      ],
      currencyformat: {
        symbol: "N",
        thousandsSeparator: ",",
        fractionCount: "",
        fractionSeparator: "",
        symbolPosition: "front",
        symbolSpacing: "",
        avoidEmptyDecimals: undefined,
      },
      payablesBalances: {
        total: 0,
        expense: 0,
        vendors: 0,
        payees: 0,
      },
    };
  },
  components: {
    Allpayables,
    ScheduledPayables,
    PendingPayables,
    PaidPayables,
    BudgetPayables,
    PureVueChart,
    PaymentDropDown,
    DropDownMenu,
    BaseTitleBar,
    CardAvatar,
    SingleSummaryCard,
    ReceivableCards,
  },
  mixins: [currencyFilter],
  computed: {
    currentBillPercentage() {
      const expectedPayment = this.payablesBalances.total;
      const paymentDue = this.payablesBalances.expense;

      if (paymentDue === 0) {
        return 0;
      }

      return ((expectedPayment / paymentDue) * 100).toFixed(2);
    },

    currentPayeesPercentage() {
      const totalDebtors = 340;
      const last30DaysDebtors = 60;

      if (last30DaysDebtors === 0) {
        return 0;
      }

      return ((totalDebtors / last30DaysDebtors) * 100).toFixed(2);
    },

    //return last 6 months short name from current month in a reversed order in an array
    months() {
      let lastSixMonths = [];
      let month = new Date().getMonth();
      for (let i = 0; i < 6; i++) {
        lastSixMonths.push(
          new Date(0, month - i, 1).toLocaleString("default", {
            month: "short",
          })
        );
      }
      return lastSixMonths.reverse();
    },

    //use the months array to get a key value pair of month and number of customers
    paymentPerMonthData() {
      let paymentPerMonthData = [];
      for (let i = 0; i < this.months.length; i++) {
        paymentPerMonthData.push({
          label: this.months[i],
          value: this.paymentPerMonth[i],
        });
      }
      return paymentPerMonthData;
    },

    //getter
    ...mapGetters({
      payables: "payables/getAllPayables",
      organizationToken: "organizations/OrganToken",
    }),
  },
  methods: {
    toggleSearch() {
      this.isClicked = false;
    },
    searchDataTable(e) {
      this.$refs.payablesTable.setSearchText(e);
    },

    getAmt(val) {
      if (val) {
        return Intl.NumberFormat("en-NG", {
          currency: this.$appCurrencySymbolAndSigns.currencySymbol,
          style: "currency",
        }).format(val);
      } else {
        return `${this.$appCurrencySymbolAndSigns.currencySign}0.00`;
      }
    },

    //dispatch the vuex action to get all payables using async await and try catch
    async getAllPayables() {
      try {
        const organHypnId = await this.organizationToken.data.hypn_id;
        const { data } = await this.$store.dispatch(
          "payables/getAllPayables",
          organHypnId
        );

        this.payablesBalances = data.summary;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    //call the getPayables method on component mount
    // this.getAllPayables();
  },
  watch: {
    //watch the organizationToken value and if its not null call the getAllPayables method
    organizationToken: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val != null) {
          // this.$store.dispatch("payables/getAllPayables", val.data.hypn_id);
          this.getAllPayables();
        }
      },
    },
  },
};
</script>

<style scoped>
.v-input .search-field .v-input__slot:before,
.v-input .search-field .v-input__slot:after {
  border: none !important;
  border-color: transparent !important;
}

.transTotal {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #7f919b;
}

.v-menu__content {
  box-shadow: none;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

th {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--v-primary-base);
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.cardTitle {
  margin-top: 32px;
  margin-left: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  color: rgba(0, 35, 56, 0.5);
}

.cardSubTitle {
  margin-top: 5px;
  margin-left: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.545455px;
  color: rgba(0, 35, 56, 0.5);
}

#histogram {
  fill: #96ead7 !important;
}

.subTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.545455px;
  color: #525e6e;
}

.subTitle__value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.545455px;
  color: #525e6e;
}

.card__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.total__customers {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #19283d;
}

.tab__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
</style>
