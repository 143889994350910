<template>
  <div>
    <!-- <div>
      <v-card height="60px" width="100%" flat class="d-flex" style="background: rgba(127, 145, 155, 0.052607)">
        <v-switch flat dense class="pl-4 mb-2" color="#16be98" v-model="autoPay" label="Auto pay"></v-switch>
      </v-card>
    </div> -->
    <v-skeleton-loader type="table" :loading="isLoading">
      <v-layout
        row
        wrap
        class="align-center my-2 py-2 pl-10 pr-6"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-flex md1>
          <div class="d-flex">
            <p class="mb-0 primary--text font-weight-bold">#</p>
          </div>
        </v-flex>

        <v-flex md2>
          <div class="d-flex align-center text-center">
            <p class="mb-0 primary--text font-weight-bold">Amount</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Currency</p>
          </div>
        </v-flex>
        <v-flex md3>
          <div class="d-flex align-center ml-2">
            <p class="mb-0 primary--text font-weight-bold">Ref No.</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Status</p>
          </div>
        </v-flex>
        <v-flex md2>
          <div class="d-flex align-center">
            <p class="mb-0 primary--text font-weight-bold">Payee</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div>
            <p class="mb-0 primary--text font-weight-bold">Date</p>
          </div>
        </v-flex>
        <v-flex md1>
          <div class="d-flex align-center justify-end">
            <p class="mb-0 primary--text font-weight-bold">Actions</p>
          </div>
        </v-flex>
      </v-layout>
      <v-row v-if="filteredPayables && filteredPayables.length > 0">
        <v-col
          cols="12"
          v-for="(payable, i) in displayedItems"
          :key="i"
          class="py-0 px-0 px-md-3 ma-0"
        >
          <PaymentTable
            v-if="$vuetify.breakpoint.mdAndUp"
            :index="i + 1"
            :id="payable.id"
            :paymentRef="payable.reference"
            :currency="
              payable.currency === 'NGN'
                ? '₦'
                : payable.currency === 'USD'
                ? '$'
                : payable.currency === null
                ? '₦'
                : payable.currency
            "
            :approvedBy="payable.approved_by.email"
            :payee="payable.counterparty.vendorname"
            :date="payable.created_at | date"
            :amount="payable.total"
            :status="payable.status"
            @openDetails="openDetails(payable.id)"
          />
          <!-- Data table for mobile -->
          <PayableTableCard
            v-if="$vuetify.breakpoint.smAndDown"
            :index="i + 1"
            :id="payable.id"
            :paymentRef="payable.reference"
            :currency="
              payable.currency === 'NGN'
                ? '₦'
                : payable.currency === 'USD'
                ? '$'
                : payable.currency === null
                ? '₦'
                : payable.currency
            "
            :approvedBy="payable.approved_by.email"
            :payee="payable.counterparty.vendorname"
            :date="payable.created_at | date"
            :amount="payable.total"
            :status="payable.status"
            @openDetails="openDetails(payable.id)"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center pt-6" v-else>
        <span class="text--primary text-h5 font-monospace"
          >No Data available</span
        >
      </div>
    </v-skeleton-loader>
    <!-- pagination component -->
    <div
      class="pagination__container px-8"
      v-if="filteredPayables && filteredPayables.length > 0"
    >
      <BasePagination
        :current-page="currentPage"
        :visible-pages="visiblePageValue"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
    <!-- right sided vuetify navigation drawer -->
    <div>
      <v-navigation-drawer
        width="360px"
        temporary
        right
        v-model="detailsDrawer"
        :hide-overlay="true"
        app
        style="box-shadow: 0px 4px 16px rgba(192, 196, 202, 0.15)"
      >
        <div
          class="d-flex justify-center align-center"
          style="height: 100vh; width: 100%"
          v-if="loadingDetails"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <PayableDetails
          :entryId="singleEntryId"
          @close="detailsDrawer = false"
          v-else
        />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentTable from "./PaymentTable.vue";
import PayableTableCard from "./PayableTableCard.vue";

import PayableDetails from "@/pages/payables/components/payableDetails.vue";
import BasePagination from "@/components/BasePagination.vue";
import pagination from "@/mixins/pagination.js";
export default {
  components: {
    PaymentTable,
    PayableTableCard,
    PayableDetails,
    BasePagination,
  },
  mixins: [pagination],
  data() {
    return {
      autoPay: false,
      searchQuery: "",
      itemsPerPage: 10,
      detailsDrawer: false,
      isLoading: false,
      loadingDetails: false,
      payId: "",
      singleEntryId: null,
    };
  },
  methods: {
    setSearchText(value) {
      console.log(value);
      this.searchQuery = value;
    },

    // open details drawer
    async openDetails(id) {
      const req_data = {
        organization_hypnID: this.organizationToken.data.hypn_id,
        payable_id: id,
      };

      this.detailsDrawer = true;
      this.loadingDetails = true;
      const { data } = await this.$store.dispatch(
        "payables/getSinglePayable",
        req_data
      );
      this.singleEntryId = data?.data?.entry;
      this.loadingDetails = false;
    },
  },
  computed: {
    //vuex getters..
    ...mapGetters({
      payables: "payables/getPaidPayables",
      organizationToken: "organizations/OrganToken",
    }),

    //filter payables for search
    filteredPayables() {
      const searchQuery = this.searchQuery.trim().toLowerCase();

      if (!searchQuery) {
        return this.payables;
      }

      return this.payables.filter((payable) => {
        const matchProperty = (property) =>
          (property || "").toLowerCase().includes(searchQuery);

        const payee = payable?.counterparty?.vendorname;
        const approvedBy = payable?.approved_by?.email;
        const status = payable?.status;
        const paymentRef = payable?.reference;

        return (
          matchProperty(payee) ||
          matchProperty(approvedBy) ||
          matchProperty(status) ||
          matchProperty(paymentRef)
        );
      });
    },

    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredPayables.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (
        this.filteredPayables &&
        this.filteredPayables.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredPayables?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredPayables?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },
    // get the total pages
    totalPages() {
      return Math.ceil(this.filteredPayables.length / this.itemsPerPage);
    },
  },
};
</script>

<style scoped>
.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}
</style>
