<template>
  <div v-if="singlePayable" class="detail-drawer">
    <!-- TODO: add a v-if condition for singlePayables in the div above -->
    <div class="d-flex justify-space-between align-center mb-4">
      <p class="title">Payable Detail</p>
      <v-btn @click="close" icon color="primary">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="section__div">
      <p class="headerText">Amount</p>
      <p class="amountText" v-if="singlePayable">
        {{ singlePayable.total | currency({ symbol: singlePayable.currency }) }}
      </p>
      <!-- <p class="amountText">
        {{ singleReceivable.total | currency(currencyWithShortCode) }}
      </p> -->
    </div>
    <div class="section__div">
      <p class="headerText">Due Date</p>
      <!-- <p class="valueText">{{ singleReceivable.invoice.due_date }}</p> -->
      <p class="valueText">{{ singlePayable.invoice.due_date }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">Paid</p>
      <!-- <p class="valueText">
        {{ singleReceivable.paid | currency({ symbol: singlePayable.currency }) }}
      </p> -->
      <p class="valueText">--</p>
    </div>
    <div class="section__div">
      <p class="headerText">Payee</p>
      <p class="valueText">{{ singlePayable.counterparty.vendorname }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">Beneficiary Type</p>
      <p class="valueText">{{ singlePayable.counterparty.type }}</p>
    </div>
    <div class="section__div">
      <p class="headerText">ID</p>
      <p class="valueText">{{ singlePayable.hypn_id }}</p>
    </div>
    <!-- <div class="section__div">
      <p class="headerText">Attach Document</p>
      <p class="linkText" @click="openFileInput">Upload</p>
    </div> -->
    <!-- <div>
      <v-file-input ref="fileInput" accept=".png, .jpeg, .jpg, .pdf" style="display: none"></v-file-input>
    </div> -->
    <div class="approval-container">
      <div class="comment">
        <template>
          <div class="view-comments" v-if="approvals && approvals.length > 0">
            <img :src="require('@/assets/approval-approved.svg')" alt="" />
            <button @click="showApprovals = !showApprovals">
              {{ showApprovals ? "hide" : "view" }} approvals
            </button>
          </div>
          <div class="titleh" :class="{ show: showApprovals }">
            Below is a timeline of approvals for this form entry
          </div>

          <div
            class="timeline"
            :class="{ show: showApprovals }"
            v-if="approvals && approvals.length > 0"
          >
            <span v-for="(approval, index) in approvals" :key="index">
              <div class="timeline__data">
                <div class="head">
                  <span class="approver">{{ approval.approver.username }}</span>
                  <span class="status"
                    >{{ approval.is_approved ? "Approved" : "Denied" }}
                    <img
                      :src="
                        require(`@/assets/approval-${
                          approval.is_approved ? 'approved' : 'denied'
                        }.svg`)
                      "
                    />
                  </span>
                </div>

                <div class="quote">
                  {{ approval.comment }}
                </div>

                <div class="date">
                  {{ approval.created_at | date("full") }}
                </div>
              </div>
              <div
                v-if="index !== approvals.length - 1"
                class="timeline__divider"
              ></div>
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currencyFilter from "@/mixins/currencyFilter.js";
export default {
  name: "PayableDetails",

  props: {
    entryId: null,
  },

  data() {
    return {
      showApprovals: false,
    };
  },

  computed: {
    ...mapGetters({
      singlePayable: "payables/getSinglePayable",
      approvals: "transactions/getFormEntryApprovals",
    }),
  },
  mixins: [currencyFilter],
  methods: {
    // close drawer
    close() {
      this.$emit("close");
    },
    // get approval comments using the entry id
    async getEntryApprovals(id) {
      try {
        this.isLoadingDetails = true;
        await this.$store.dispatch("transactions/getTransactionApprovals", id);
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "error fetching approvals",
          timeout: 3000,
        });
      } finally {
        this.isLoadingDetails = false;
      }
    },

    // handle file upload
    openFileInput() {
      this.$refs.fileInput.click();
    },
  },
  watch: {
    entryId: {
      handler: function (val) {
        if (val) {
          this.getEntryApprovals(val);
        }

        // console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

.detail-drawer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 40px;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .section__div {
    padding-bottom: 10px;

    .headerText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #8f96a1;
      display: block;
    }

    .valueText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }

    .amountText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #19283d;
      display: block;
    }
  }
}

.section__div {
  padding-bottom: 10px;

  .headerText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #8f96a1;
    display: block;
  }

  .valueText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .amountText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #19283d;
    display: block;
  }

  .linkText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    cursor: pointer;
    color: #5b67ba;
    text-decoration: underline;
  }
}

.details-wrapper {
  padding: 20px;
}

.content::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-columns: repeat(2, minmax(0, 1fr));
  // flex: 1;
  // overflow: auto;
  // margin-top: 5px;
  // &__action {
  //   padding: 0px 5px;
  //   cursor: pointer;
  //   margin: 20px 0px;
  //   &:hover {
  //     span {
  //       color: #d7a47b;
  //     }
  //   }
  //   img {
  //     width: 30px;
  //     height: 30px;
  //     display: block;
  //     margin: auto;
  //   }
  //   span {
  //     display: block;
  //     text-align: center;
  //     margin-top: 10px;
  //     color: #7f919b;
  //   }
  //   .coming-soon {
  //     font-family: "Inter";
  //     opacity: 0.9;
  //     margin-top: 1px;
  //     color: #d7a47b;
  //   }
  // }

  .card {
    background: #f8f9fc;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px 20px;

    &__value {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 28px;
      color: #19283d;
    }

    &__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      color: #8f96a1;
      opacity: 0.8;
    }
  }
}

.approval-container {
  height: 25vh;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }
}

.comment {
  flex: 1;

  //1300px and above
  @media screen and (min-width: 1300px) {
    overflow: auto;
  }

  margin: 5px 0;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }

  .view-comments {
    text-align: left;
    margin: 20px 0;
    color: #5b67ba;
    display: flex;
    align-items: center;
    gap: 7px;

    img {
      width: 20px;
      height: 20px;
    }

    button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
    }
  }

  .titleh {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #525e6e;
    display: none;

    &.show {
      display: block;
    }
  }

  .timeline {
    flex: 1;
    overflow-y: auto;

    height: 0px;
    transition: all 0.3s ease-in-out;

    &.show {
      height: auto;
      padding: 30px 0px;
    }

    &__divider {
      margin: auto;
      height: 35px;
      width: 1px;
      border-right: 2px dashed #8f96a1;
    }

    &__data {
      box-sizing: border-box;
      padding: 15px;

      background: #ffffff;
      /* line */

      border: 1px solid #d9dee1;
      border-radius: 4px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;

        .approver {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 28px;
          color: #19283d;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status {
          font-family: "Inter";
          font-style: italic;
          font-weight: 500;
          font-size: 13px;
          line-height: 28px;
          color: #8f96a1;
          display: inline-flex;
          align-items: center;
          gap: 5px;
        }
      }

      .quote {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15;
        line-height: 24px;
        /* or 200% */

        /* menu txt */

        color: #525e6e;
        margin: 10px 0px;
      }

      .date {
        text-align: right;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(25, 40, 61, 0.8);
      }
    }
  }
}
</style>
