var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pl-10 pr-6 py-6",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},[_c('v-layout',{staticClass:"align-center justify-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md1":""}},[_c('span',{staticClass:"mb-0 table-input"},[_vm._v(_vm._s(_vm.index))])]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mb-0 table-input"},[_vm._v(_vm._s(_vm.amount))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mb-0 table-input"},[_vm._v(_vm._s(_vm.currency))])])]),_c('v-flex',{attrs:{"md3":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mb-0 table-input text-truncate",staticStyle:{"max-width":"200px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.paymentRef)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.paymentRef))])])],1)]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex"},[(_vm.status)?_c('v-icon',{attrs:{"small":"","color":_vm.statusIcon}},[_vm._v(" mdi-circle-medium ")]):_vm._e(),_c('span',{staticClass:"table-input"},[_vm._v(_vm._s(_vm.status))])],1)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mb-0 table-input text-truncate"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.payee)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.payee))])])],1)]),_c('v-flex',{attrs:{"md1":""}},[_c('div',[_c('span',{staticClass:"mb-0 table-input"},[_vm._v(_vm._s(_vm.date))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 mr-1 rounded",staticStyle:{"color":"var(--v-primary-base)"},style:({
                  'background-color': hover ? '#2bd5ae' : '',
                  border: hover ? 'none' : '',
                }),attrs:{"exact-path":"","depressed":"","outlined":"","dark":"","small":"","color":"#2BD5AE"},on:{"click":function($event){return _vm.$emit('openDetails', _vm.id)}}},[_vm._v(" Details ")])]}}],null,true)})],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }